
    <div class="mdc-layout-grid products"  >
        <div class="mdc-layout-grid__inner">
          <div class="mdc-layout-grid__cell">
            <mat-card class="card">
              <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>PIÑA</mat-card-title>
                <mat-card-subtitle>Villa Canales</mat-card-subtitle>
              </mat-card-header>
              <div class="box second"></div>
              <img mat-card-image class="image_card center" src="/assets/pinias.png"  alt="Photo of a Shiba Inu">
              <mat-card-content>
                <p>
                  Cultivadas en tierra caliente, tenemos la producción de piñas dulces y con la mejor calidad del mercado
                </p>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="mdc-layout-grid__cell">
            <mat-card class="card">
              <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>LEATHER LEAFT</mat-card-title>
                <mat-card-subtitle>Cobán</mat-card-subtitle>
              </mat-card-header>
              <div class="box first"></div>
              <img mat-card-image class="image_card center" src="/assets/leather.jpeg"  alt="Photo of a Shiba Inu">
              <mat-card-content>
                <p>
                  Leather Leaft(Hoja de Cuero), una de las mas hermosas plantas ornamentales,
                </p>
              </mat-card-content>
            
            </mat-card>
          </div>
          <div class="mdc-layout-grid__cell">
            <mat-card class="card">
              <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>ARALIA VERDE</mat-card-title>
                <mat-card-subtitle>Santa Rosa</mat-card-subtitle>
              </mat-card-header>
              <div class="box thirt"></div>
              <img mat-card-image class="image_card center" src="/assets/aralia.jpeg"  alt="Photo of a Shiba Inu">
              <mat-card-content>
                <p>
                  Leather Leaft(Hoja de Cuero), una de las mas hermosas plantas ornamentales,
                </p>
              </mat-card-content>
            
            </mat-card>
          </div>
          <div class="mdc-layout-grid__cell">
            <mat-card class="card">
              <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>COCULUS</mat-card-title>
                <mat-card-subtitle>Santa Rosa</mat-card-subtitle>
              </mat-card-header>
              <div class="box first"></div>
              <img mat-card-image class="image_card center" src="/assets/coculus.jpeg"  alt="Photo of a Shiba Inu">
              <mat-card-content>
                <p>
                  Leather Leaft(Hoja de Cuero), una de las mas hermosas plantas ornamentales,
                </p>
              </mat-card-content>
            
            </mat-card>
          </div>
          <div class="mdc-layout-grid__cell">
            <mat-card class="card">
              <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>TREEFERNS</mat-card-title>
                <mat-card-subtitle>Santa Rosa</mat-card-subtitle>
              </mat-card-header>
              <div class="box second"></div>
              <img mat-card-image class="image_card center" src="/assets/treeferns.jpeg"  alt="Photo of a Shiba Inu">
              <mat-card-content>
                <p>
                  Leather Leaft(Hoja de Cuero), una de las mas hermosas plantas ornamentales,
                </p>
              </mat-card-content>
            
            </mat-card>
          </div>

          <div class="mdc-layout-grid__cell">
            <mat-card class="card">
              <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>PITTOSPORUM</mat-card-title>
                <mat-card-subtitle>Santa Rosa</mat-card-subtitle>
              </mat-card-header>
              <div class="box thirt"></div>
              <img mat-card-image class="image_card center" src="/assets/pittosporum.jpeg"  alt="Photo of a Shiba Inu">
              <mat-card-content>
                <p>
                  Leather Leaft(Hoja de Cuero), una de las mas hermosas plantas ornamentales,
                </p>
              </mat-card-content>
            
            </mat-card>
          </div>
        </div>
      </div>

